<template>
  <div class="navBar">
    <div class="navBar_content">
      <div class="logoBox">
        <img src="@/assets/logo.png">
      </div>
      <div class="navBox">
        <div v-for="(item,index) in navList"
          :key="index"
          class="navItem"
          @mouseover="setHoverName(item.name)"
          @mouseout="hoverName = ''"
          @click="toPage(item)">
          <span>
            {{ item.title }}
          </span>

          <div class="theSelect"
            v-if="item.name == 'product'"
            :class="hoverName === item.name && 'theSelectActive'">
            <div v-for="(child,childIndex) in item.child"
              :key="childIndex"
              class="theSelectItem"
              @click.stop="toPage(child)">
              {{ child.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <el-button type="primary"><a href="https://mall.24ktpv.com/#/login"
            target="_blank">免费试用</a></el-button>
        <el-tooltip class="item"
          effect="dark"
          placement="bottom">
          <div slot="content">
            <img src="@/assets/kefuCode.png"
              class="codeImg">
          </div>
          <img src="@/assets/kefu.png"
            class="kefu">
        </el-tooltip>
      </div>
      <div class="rightBoxPhone">
        <div class="btn"><a href="https://mall.24ktpv.com/#/login">免费试用</a></div>
        <i class="iconfont icon-caidan_o"
          @click="phoneMenuShow = !phoneMenuShow"></i>
      </div>
    </div>

    <div class="phoneMenu"
      :class="phoneMenuShow ? 'menuShow':'menuClose'"
      @click="phoneMenuShow = false"></div>
    <div class="menuContent"
      :class="phoneMenuShow ? 'open':'close'">
      <div class="closeBox">
        <i class="iconfont icon-guanbi"
          @click="phoneMenuShow = false"></i>
      </div>

      <div v-for="(item,index) in navList"
        :key="index"
        :class="item.child ? '':'routerItem'"
        @click="toPage(item)">
        <div :class="item.child ? 'routerTitle':'' ">
          <span :class="ruoterName === item.name && 'textActive'">{{item.title}}</span>
        </div>
        <ul v-if="item.child">
          <li v-for="(childPath,childIndex) in item.child"
            :key="childIndex"
            class="routerItem"
            @click.stop="toPage(childPath)"
            :class="ruoterName === childPath.name && 'textActive'">{{(childPath.title)}}</li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      navList: [
        {
          title: '首页',
          path: '/',
          name: 'index'
        },
        {
          title: '产品介绍',
          path: '/wholesale',
          name: 'product',
          child: [
            {
              title: '批发商',
              path: '/wholesale',
              name: 'wholesale'
            },
            {
              title: '零售商',
              path: '/retail',
              name: 'retail'
            },
            {
              title: '餐饮店',
              path: '/restaurant',
              name: 'restaurant'
            }
          ]
        },
        {
          title: 'APP下载',
          path: '/act',
          name: 'act'
        },
        {
          title: '关于我们',
          path: '/aboutUs',
          name: 'aboutUs'
        }
      ],
      hoverName: 'hoverName',
      phoneMenuShow: false
    }
  },
  components: {},
  computed: {
    ruoterName() {
      return this.$route.name
    }
  },
  created() {},
  mounted() {},
  methods: {
    setHoverName(name) {
      this.hoverName = name
    },
    toPage(item) {
      this.$router.push(item.path)
    }
  }
}
</script>

<style scoped lang="scss">
.navBar_content {
  background: #275de1;
  backdrop-filter: blur(10px);
  height: 63px;
  z-index: 5555;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 34px;
  .logoBox {
    width: 140px;
    height: 48px;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 9px;
    // padding: 4px 5px;
    img {
      width: 100%;
    }
  }
  .navBox {
    display: flex;
    align-items: center;
    width: 499px;
    height: 46px;
    background: rgba($color: #fff, $alpha: 0.2);
    border-radius: 20px;
    padding: 0 17px;
    display: flex;
    justify-content: space-between;
    font-size: 19px;
    font-weight: 500;
    color: #ffffff;
    .navItem {
      height: 100%;
      line-height: 45px;
      cursor: pointer;
      position: relative;
      span {
        &:hover {
          color: #275de1;
        }
      }
    }
  }
  .rightBox {
    cursor: pointer;
    display: flex;
    align-items: center;
    > div {
      margin-right: 20px;
    }
    a {
      text-decoration: none;
      color: white;
    }
    .el-button {
      background: white;
      border: none;
      margin-right: 20px;
      a {
        color: #275de1 !important;
        font-weight: 500;
      }
    }
    .kefu {
      width: 40px;
      height: 40px;
    }
  }
}

.theSelect {
  position: absolute;
  width: 260px;
  height: 0;
  left: calc(50% - 130px);
  bottom: -78px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url(../assets/navSelectBg.png);
  background-position: bottom;
  background-size: 260px;
  background-repeat: no-repeat;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0 18px;
  .theSelectItem {
    font-size: 19px;
    font-weight: 500;
    color: #282828;
    &:hover {
      color: #275de1;
    }
  }
}
.theSelectActive {
  padding-top: 20px;
  height: 78px !important;
  transition: height 0.3s;
}
.codeImg {
  width: 200px;
}
.rightBoxPhone {
  display: none;
}
.menuContent {
  display: none;
}
@media screen and (max-width: 768px) {
  .navBar_content {
    height: 6rem;
    padding: 0.6rem;
    .logoBox {
      width: 100px;
      height: auto;
    }
    .navBox {
      display: none;
    }
    .rightBox {
      display: none;
    }
    .rightBoxPhone {
      height: 6rem;
      display: flex;
      align-items: center;
      .btn {
        background: white;
        height: 3rem;
        width: 6rem;
        border-radius: 0.6rem;
        text-align: center;
        line-height: 3rem;
        a {
          color: #275de1 !important;
          font-weight: 500;
          text-decoration: none;
        }
      }
      i {
        font-size: 2.8rem;
        color: white;
        margin-left: 0.6rem;
      }
    }
  }
  .phoneMenu {
    position: fixed;
    right: 0;
    width: 100%;
    height: 200vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000;
  }
  .menuShow {
    display: block;
  }
  .menuClose {
    display: none;
  }
  .menuContent {
    z-index: 2000;
    box-sizing: border-box;
    display: block;
    color: black;
    position: fixed;
    right: 0;
    top: 0;

    .closeBox {
      padding: 0 1rem;
      font-size: 3rem;
      height: 6.5rem;
      line-height: 6.5rem;
      text-align: right;
    }
    .routerTitle {
      height: 5rem;
      line-height: 5rem;
      font-size: 1.8rem;
      font-weight: bold;
    }
    .routerItem {
      height: 5rem;
      line-height: 5rem;
      font-size: 1.8rem;
    }
    ul {
      margin-left: 1.6rem;
    }
    li {
      list-style: none;
    }
  }
  .open {
    background: white;
    height: 100%;
    width: 60%;
    transition: width 0.3s;
    padding-left: 1.4rem;
  }
  .close {
    background: white;
    height: 100%;
    width: 0;
    transition: width 0.3s;
  }

  .textActive {
    color: #275de1;
  }
}
</style>
