<template>
  <div>
    <div class="banner">
      <div class="computer">
        <div class="title">专业定制的零售收银系统</div>
        <div class="smallTitle">解决批发商业务所需的零售收银系统</div>
        <div class="btn">预约演示</div>
      </div>
      <img src="@/assets/newAssets/retail1.png">
      <div class="phone">
        <div class="title">专业定制的零售收银系统</div>
        <div class="smallTitle">解决批发商业务所需的零售收银系统</div>
      </div>
    </div>
    <div class="page5">
      <div>
        <div class="title">
          为西班牙华商定制的零售收银系统
          <div></div>
        </div>
        <div class="content">
          <div class="">
            <img src="@/assets/page5Img1.png">
            <div>
              <div class="subtitle">POS机收银</div>
              <div class="textDetail">时尚大气的收银设备，提<br>升门店形象 </div>
            </div>
          </div>
          <div class="">
            <img src="@/assets/page5Img2.png">
            <div>
              <div class="subtitle">营销工具</div>
              <div class="textDetail">种类多样的营销工具，帮<br>助您占领市场</div>
            </div>
          </div>
          <div class="">
            <img src="@/assets/page5Img3.png">
            <div>
              <div class="subtitle">数据分析</div>
              <div class="textDetail">多种数据报表，助您精准<br>把握市场动向 </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.phone {
  display: none;
}
.computer {
  display: block;
}
.banner {
  background: #f0f2f5;
  padding: 85px 80px 58px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title,
  .smallTitle {
    font-size: 35px;
    font-weight: 500;
    color: #333333;
    line-height: 67px;
    letter-spacing: 2px;
  }
  .smallTitle {
    font-size: 22px;
    margin-bottom: 56px;
  }
  .btn {
    text-align: center;
    width: 166px;
    height: 56px;
    background: #275de1;
    border-radius: 32px;
    color: white;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }
  img {
    width: 500px;
    margin-left: 100px;
  }
}
.page5 {
  width: 100%;
  position: relative;
  background: #f0f2f5;
  padding-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 37px;
    font-weight: 500;
    color: #333333;
    line-height: 52px;
    letter-spacing: 2px;
    // margin-top: 50px;
    text-align: center;
    margin-bottom: 26px;
    > div {
      width: 175px;
      height: 5px;
      background: #2e93fe;
      opacity: 0.6;
      margin: 0 auto;
      margin-top: 16px;
    }
  }
  .content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      position: relative;
      width: 336px;
      height: 442px;
      > img {
        width: 100%;
        height: 100%;
      }
      > div {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        padding-top: 42px;
        padding-left: 33px;
        z-index: 1;
        font-size: 19px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        .subtitle {
          font-size: 29px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 4px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .phone {
    display: block;
  }
  .computer {
    display: none;
  }
  .middleBox {
    width: 100%;
  }
  .page5 {
    .title {
      font-size: 2.2rem;
      line-height: normal;
    }
    .content {
      width: 100%;
      flex-wrap: wrap;
      > div {
        width: 98%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
  }

  .banner {
    padding: 0;
    flex-wrap: wrap;
    padding: 1.2rem 0;
    > img {
      height: auto;
      width: 100%;
      margin: 0;
    }
    .phone {
      width: 100%;
      padding: 0 10px;
      text-align: center;
    }
    .title {
      font-size: 2.2rem;
      line-height: normal;
      margin-top: 10px;
    }
    .smallTitle {
      font-size: 1.6rem;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}
</style>
