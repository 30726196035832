<template>
  <div>
    <div class="page10">
      <img src="@/assets/page10img1.png"
        alt="">
      <div class="title">
        <div class="line"></div>
        <div>关于我们</div>
        <div class="line"></div>
      </div>
      <div class="textContent">
        <!-- 我们是一家同时在伊比利亚半岛和中国苏州设置机构的高新技术企业，致力于为海外华商提供卓越的信息技术服务。
      我们的业务经营团队位于伊比利亚半岛，旨在就近为华商提供贴身服务，并同时在欧洲和中国时间提供在线技术支持。我们
      的产品研发团队位于中国苏州，拥有约20人的专业员工，身在中国使我们更能理解华商的经营需求。我们的业务涵盖批
      发业、零售业、餐饮业的进销存系统、收银管理系统，同时提供与管理系统配套的硬件设备，为海外华商提供一站式解决方案。 -->
        <div> 我们是一家同时在伊比利亚半岛和中国苏州设置机构的高新技术企业，致力于为海外华商提供卓越的信息技术服务。</div>
        <div>我们的业务经营团队位于伊比利亚半岛，旨在就近为华商提供贴身服务，并同时在欧洲和中国时间提供在线技术支持。</div>
        <div> 我们的产品研发团队位于中国苏州，拥有约20人的专业员工，身在中国使我们更能理解华商的经营需求。</div>
        <div>我们的业务涵盖批发业、零售业、餐饮业的进销存系统、收银管理系统，同时提供与管理系统配套的硬件设备，为海外华商提供一站式解决方案。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.page10 {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #d7e4ef;
  // padding-top: 80px;
  overflow: auto;
  img {
    width: 100%;
    height: 46%;
  }
}
.title {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #333333;
  .line {
    width: 50px;
    height: 5px;
    background: #2e93fe;
  }
  > div {
    &:nth-child(2) {
      margin: 0 20px;
    }
  }
}
.textContent {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 30px 50px;
  background: #ffffff;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 30px;
  letter-spacing: 3px;
}
@media screen and (max-width: 768px) {
  .page10 {
    width: 100vw;
    position: fixed;
    top: 6rem;
    left: 0;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  .textContent {
    width: 100%;
    padding: 1.2rem;
  }
}
</style>
