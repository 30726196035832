<template>
  <div>
    <div class="banner">
      <div class="computer">
        <div class="title">餐馆收银系统</div>
        <div class="smallTitle">从点餐到餐桌，每一步都为您精心制作</div>
        <div class="btn">预约演示</div>
      </div>
      <img src="@/assets/newAssets/restaurant1.png">
      <div class="phone">
        <div class="title">餐馆收银系统</div>
        <div class="smallTitle">从点餐到餐桌，每一步都为您精心制作</div>
      </div>
    </div>
    <div class="page8">
      <div>
        <div class="title">
          适合各种餐饮业态
          <div></div>
        </div>
        <div class="content">
          <div>
            <img src="@/assets/page8img1.png">
            <div>
              <div class="subtitle">收银台点餐 </div>
              <div> 操作简单上手即会，无需培训，提升收银点餐<br>
                效率，减少客户等待时间</div>
            </div>
          </div>
          <div>
            <img src="@/assets/page8img2.png">
            <div>
              <div class="subtitle">服务员当面点餐 </div>
              <div> 服务员使用手持设备点餐，就餐体验感更<br>
                好，顾客更满意</div>
            </div>
          </div>
          <div>
            <img src="@/assets/page8img3.png">
            <div>
              <div class="subtitle">顾客自助点餐 </div>
              <div> 顾客自助点餐，店里就能减少大量的人力开<br>
                销，同时还能提升点餐效率，提升坪效</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page7">
      <div>
        <div class="title">
          适合各种餐饮业态
          <div></div>
        </div>
        <div class="content">
          <div>
            <img src="@/assets/page7img1.png"
              alt="">
            <div>正餐馆</div>
          </div>
          <div>
            <img src="@/assets/page7img2.png"
              alt="">
            <div>快餐店</div>
          </div>
          <div>
            <img src="@/assets/page7img3.png"
              alt="">
            <div>奶茶店</div>
          </div>
          <div>
            <img src="@/assets/page7img4.png"
              alt="">
            <div>外卖店</div>
          </div>
          <div>
            <img src="@/assets/page7img5.png"
              alt="">
            <div>咖啡厅</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.phone {
  display: none;
}
.computer {
  display: block;
}
.banner {
  background: #f0f2f5;
  padding: 44px 80px 58px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title,
  .smallTitle {
    font-size: 35px;
    font-weight: 500;
    color: #333333;
    line-height: 67px;
    letter-spacing: 2px;
  }
  .smallTitle {
    font-size: 22px;
    margin-bottom: 56px;
  }
  .btn {
    text-align: center;
    width: 166px;
    height: 56px;
    background: #275de1;
    border-radius: 32px;
    color: white;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }
  img {
    width: 520px;
    margin-left: 100px;
  }
}
.page8 {
  width: 100%;
  // height: 100vh;
  position: relative;
  background: #f9faff;
  padding: 42px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 37px;
    font-weight: 500;
    color: #333333;
    line-height: 52px;
    letter-spacing: 2px;
    // margin-top: 50px;
    text-align: center;
    margin-bottom: 50px;
    > div {
      width: 175px;
      height: 5px;
      background: #2e93fe;
      opacity: 0.6;
      margin: 0 auto;
      margin-top: 16px;
    }
  }
  .content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      border-radius: 14px;
      width: 350px;
      img {
        width: 100%;
        vertical-align: top;
      }
      > div {
        border-radius: 14px;
        text-align: center;
        padding: 33px 0;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        .subtitle {
          font-size: 26px;
          font-weight: 500;
          color: #ffffff;
          line-height: 36px;
          letter-spacing: 1px;
          margin-bottom: 12px;
        }
      }
      &:first-child {
        background: linear-gradient(135deg, #51d7f9 0%, #28aef1 100%);
      }
      &:nth-child(2) {
        background: linear-gradient(135deg, #c19cfc 0%, #8e62f7 100%);
      }
      &:last-child {
        background: linear-gradient(135deg, #9edb93 0%, #65b45a 100%);
      }
    }
  }
}
.page7 {
  width: 100%;
  // height: 100vh;
  position: relative;
  background: #f0f2f5;
  padding: 42px 0 46px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 37px;
    font-weight: 500;
    color: #333333;
    line-height: 52px;
    letter-spacing: 2px;
    // margin-top: 50px;
    text-align: center;
    margin-bottom: 50px;
    > div {
      width: 175px;
      height: 5px;
      background: #2e93fe;
      opacity: 0.6;
      margin: 0 auto;
      margin-top: 16px;
    }
  }
  .content {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    > div {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 34px;
    }
    img {
      width: 200px;
      margin-bottom: 27px;
    }
  }
}

@media screen and (max-width: 768px) {
  .phone {
    display: block;
  }
  .computer {
    display: none;
  }
  .banner {
    padding: 0;
    flex-wrap: wrap;
    padding: 1.2rem 0;
    > img {
      height: auto;
      width: 100%;
      margin: 0;
    }
    .phone {
      width: 100%;
      padding: 0 10px;
      text-align: center;
    }
    .title {
      font-size: 2.2rem;
      line-height: normal;
      margin-top: 10px;
    }
    .smallTitle {
      font-size: 1.6rem;
      line-height: normal;
      margin-bottom: 0;
    }
  }

  .page7,
  .page8 {
    .title {
      font-size: 2.2rem;
      line-height: normal;
    }
    .content {
      width: 100%;
      flex-wrap: wrap;
      > div {
        width: 98%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
