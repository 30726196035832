<template>
  <div class="index">
    <!-- <span>首页</span> -->
    <div class="middleBox content1">
      <div class="left computer">
        <div class="title">西班牙华商 进销存解决方案</div>
        <div class="textContent">批发商、零售店、餐饮店，一套系统解决所有问题</div>
        <div class="btn">预约演示</div>
      </div>
      <img src="@/assets/newAssets/index1.png">
      <div class="left phone">
        <div class="title">西班牙华商 进销存解决方案</div>
        <div class="textContent">批发商、零售店、餐饮店，一套系统解决所有问题</div>
      </div>
    </div>
    <div class="middleBox content2">
      <div class="title">
        满足批发业务所需的各种销售场景
        <div></div>
      </div>
      <div class="contentBody">
        <div>
          <div class="subTitle">
            <span>采购入库</span>
            <img src="@/assets/newAssets/index2.png">
          </div>
          <div class="textBox">
            从货柜到仓库，从供应商到库存，从入库到保质期，全面追踪每单货物的来源。
          </div>
        </div>
        <div>
          <div class="subTitle">
            <span>仓库管理</span>
            <img src="@/assets/newAssets/index3.png">
          </div>
          <div class="textBox">
            库存盘点，仓库调拨，销售配货，每个业务发生的库存变动都有对应库存变更日志。
          </div>
        </div>
        <div>
          <div class="subTitle">
            <span>连锁调货</span>
            <img src="@/assets/newAssets/index4.png">
          </div>
          <div class="textBox">
            多个店铺之间分享库存，既提高的库存的利用率，又使每个店铺仓库，销售独立分开。
          </div>
        </div>
        <div>
          <div class="subTitle">
            <span>货物出入库检查</span>
            <img src="@/assets/newAssets/index5.png">
          </div>
          <div class="textBox">
            每件货物出入库，不论是采购，销售配货，还是调拨，都支持出入库检查，可以选择全检或者抽检。
          </div>
        </div>
      </div>
    </div>
    <div class="colorBox">
      <div class="middleBox content3">
        <div class="title">
          打造满足卖家需求的产品，高效解决业务难题
          <div></div>
        </div>
        <div class="contentBody">
          <div>
            <img src="@/assets/newAssets/index6.png">
          </div>
          <div>
            <img src="@/assets/newAssets/index7.png">
          </div>
          <div>
            <img src="@/assets/newAssets/index8.png">
          </div>
        </div>
      </div>
    </div>
    <div class="middleBox content4">
      <div class="title">
        技术驱动，创新引领，全方位保驾护航
        <div></div>
      </div>
      <div class="contentBody">
        <div class="left">
          <div class="subtitle">独立部署</div>
          <div>
            <div class="textItem">
              <img src="@/assets/newAssets/duihao.png">
              <span>专属资源，旺季不宕机</span>
            </div>
            <div class="textItem">
              <img src="@/assets/newAssets/duihao.png">
              <span>店铺账号对接独立服务器储存数据，安全可靠</span>
            </div>
          </div>
        </div>
        <img src="@/assets/newAssets/index9.png">
      </div>
    </div>
    <div class="colorBox">
      <div class="middleBox content5">
        <div class="title">
          服务，以客户的成功定义成功
          <div></div>
        </div>
        <div class="contentBody">
          <div>
            <img src="@/assets/newAssets/index10.png">
            <div class="textBox">
              <div class="subTitle">服务区域广</div>
              <div class="textContent">
                1V1系统演示，覆盖全球<br>
                4大区域，20+城市运营中心
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/newAssets/index11.png">
            <div class="textBox">
              <div class="subTitle">客服响应快</div>
              <div class="textContent">
                提供专业、细致、耐心的客户
                服务，7*24H及时响应
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/newAssets/index12.png">
            <div class="textBox">
              <div class="subTitle">服务团队强</div>
              <div class="textContent">
                团队20+，实施人员从规划、部署到实施，全方位、专业可靠
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/newAssets/index13.png">
            <div class="textBox">
              <div class="subTitle">培训体系全</div>
              <div class="textContent">
                全面系统的培训，让卖家快速
                上手使用系统。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="productShow content0">
      <div class="theMiddle">
        <div class="tabsTitle">配套硬件</div>
        <div class="relativeBox"
          @mouseover="clear"
          @mouseleave="scrollBox">
          <div class="productList"
            :style="{left:left+'px'}">
            <div class="productItem"
              v-for="(item,index) in productArr"
              :key="index">
              <div class="title">{{item.title}}</div>
              <img :src="item.img">
              <!-- <div class="explain">
                欢迎选购
              </div> -->
            </div>
          </div>
        </div>

        <div class="relativeBox2">
          <div class="productList">
            <div class="productItem"
              v-for="(item,index) in productArr"
              :key="index">
              <div class="title">{{item.title}}</div>
              <img :src="item.img">
              <!-- <div class="explain">
                欢迎选购
              </div> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {},
  data() {
    return {
      productArr: [
        {
          title: '手持机',
          img: require('@/assets/index/product5.png')
        },
        {
          title: '小票机',
          img: require('@/assets/index/product2.png')
        },
        {
          title: '扫描平台',
          img: require('@/assets/index/product6.png')
        },
        {
          title: '收银POS机',
          img: require('@/assets/index/product1.png')
        },
        {
          title: '手持机',
          img: require('@/assets/index/product9.png')
        },
        {
          title: '提价器',
          img: require('@/assets/index/product8.png')
        },
        {
          title: '扫码一体机',
          img: require('@/assets/index/product7.png')
        },
        {
          title: '标签打印机',
          img: require('@/assets/index/product3.png')
        },
        {
          title: '便携标签机',
          img: require('@/assets/index/product4.png')
        }
      ],
      left: 0,
      scrollType: 'left',
      timer: null
    }
  },
  created() {},
  mounted() {
    this.scrollBox()
  },
  methods: {
    scrollBox() {
      this.timer = setInterval(() => {
        if (this.left == 0) {
          this.scrollType = 'left'
        }
        if (this.left == -580) {
          this.scrollType = 'right'
        }
        if (this.scrollType == 'left') {
          this.left--
        } else {
          this.left++
        }
      }, 10)
    },
    clear() {
      console.log(789)
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped lang="scss">
.phone {
  display: none;
}
.computer {
  display: block;
}
.index {
  background: #f4f6f8;
}
.middleBox {
  width: 1200px;
  margin: 0 auto;
}
.content1 {
  padding: 55px 0 35px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 38px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
  }
  .textContent {
    font-size: 24px;
    color: #333333;
  }
  .btn {
    width: 166px;
    height: 56px;
    background: #275de1;
    border-radius: 32px;
    margin-top: 56px;
    text-align: center;
    line-height: 56px;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
  }
  img {
    width: 580px;
    // height: 457px;
  }
}
.title {
  font-size: 37px;
  font-weight: 500;
  color: #333333;
  line-height: 52px;
  letter-spacing: 2px;
  text-align: center;
  > div {
    width: 175px;
    height: 5px;
    background: #2e93fe;
    opacity: 0.6;
    margin: 0 auto;
    margin-top: 16px;
  }
}
.content2 {
  padding: 42px 0 57px 0;
  .contentBody {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    > div {
      width: 270px;
      height: 180px;
      background: #ffffff;
      box-shadow: 0px 0 4px 0px rgba(77, 97, 118, 0.64);
      border-radius: 18px;
      padding: 22px;
      .subTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 22px;
        font-weight: 600;
        color: #333333;
        line-height: 21px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .textBox {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        color: black;
        line-height: 23px;
      }
    }
  }
}
.colorBox {
  background: #f9faff;
}
.content3 {
  padding: 42px 0 58px 0;
  .contentBody {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 337px;
    }
    > div {
      &:nth-child(2) {
        margin: 0 60px;
      }
    }
  }
}
.content4 {
  padding: 32px 0 42px 0;
  justify-content: center;
  .contentBody {
    margin-top: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      margin-right: 113px;
      .subtitle {
        font-size: 37px;
        font-weight: 500;
        color: #333333;
        line-height: 52px;
        letter-spacing: 2px;
        margin-bottom: 55px;
      }
      .textItem {
        margin-bottom: 29px;
        display: flex;
        align-items: center;
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        letter-spacing: 1px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      > img {
        width: 512px;
        height: 437px;
      }
    }
  }
}
.content5 {
  padding: 42px 0 46px 0;
  .contentBody {
    margin-top: 31px;
    display: flex;
    justify-content: space-between;
    > div {
      width: 301px;
      height: 363px;
      background: #fff;
      background-size: 100% 100%;
      background-position: top;
      position: relative;
      // &:first-child {
      //   background-image: url('../assets/newAssets/index10.png');
      // }
      // &:nth-child(2) {
      //   background-image: url('../assets/newAssets/index11.png');
      // }
      // &:nth-child(3) {
      //   background-image: url('../assets/newAssets/index12.png');
      // }
      // &:last-child {
      //   background-image: url('../assets/newAssets/index13.png');
      // }
      > img {
        width: 100%;
      }
      .textBox {
        position: absolute;
        top: 219px;
        padding: 0 27px;
        .subTitle {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
        }
        .textContent {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}

.productShow {
  height: 500px;
  background: #f5f5f7;
  padding-top: 85px;
  padding-bottom: 38px;
  .tabsTitle {
    margin-bottom: 38px;
    font-size: 40px;
    text-align: center;
    font-weight: 800;
    color: #1c3e5d;
    line-height: 56px;
    letter-spacing: 2px;
  }
  .relativeBox {
    position: relative;
    width: 1000px;
    height: 260px;
    overflow: hidden;
  }
  .relativeBox2 {
    display: none;
  }
  .productList {
    position: absolute;
    left: 0;
    display: flex;
    .productItem {
      position: relative;
      padding-top: 20px;
      text-align: center;
      width: 180px;
      height: 260px;
      background: #ffffff;
      border-radius: 14px;
      margin-right: 20px;
      .title {
        font-weight: bold;
        font-size: 16px;
      }
      img {
        width: 140px;
        margin-top: 20px;
      }
      // .explain {
      //   display: none;
      // }
      // &:hover {
      //   .explain {
      //     text-align: left;
      //     font-size: 14px;
      //     color: white;
      //     padding: 8px;
      //     display: block;
      //     border-radius: 14px;
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     width: 180px;
      //     height: 260px;
      //     background: rgba($color: #000000, $alpha: 0.8);
      //   }
      // }
    }
  }
}

@media screen and (max-width: 768px) {
  .phone {
    display: block;
  }
  .computer {
    display: none;
  }
  .middleBox {
    width: 100%;
  }
  .title {
    font-size: 2.2rem;
    line-height: normal;
  }
  .content1 {
    padding-top: 0rem !important;
    flex-wrap: wrap;
    text-align: center;
    img {
      width: 100%;
      height: auto;
    }
    .left {
      width: 100%;
      padding: 0 10px;
    }
    .title {
      font-size: 2.2rem;
      font-weight: 500;
      color: #333333;
      line-height: 60px;
    }
    .textContent {
      font-size: 1.6rem;
      color: #333333;
    }
  }
  .content2 {
    .contentBody {
      flex-wrap: wrap;
      > div {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }
  }
  .content3 {
    .contentBody {
      flex-wrap: wrap;
      > div {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
        &:nth-child(2) {
          margin: 0 auto;
        }
      }
    }
  }
  .content4 {
    .contentBody {
      flex-wrap: wrap;
      > .left {
        margin: 0 !important;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
        .subtitle {
          font-size: 2rem;
          margin-bottom: 20px;
        }
        .textItem {
          margin-bottom: 10px;
          font-size: 1.6rem;
          line-height: normal;
          img {
            width: 1.4rem;
            height: 1.4rem;
            margin-right: 10px;
          }
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .content5 {
    .contentBody {
      flex-wrap: wrap;
      > div {
        // width: 300px;

        margin: 0 auto;
        overflow: hidden;
        img {
          position: absolute;
          top: -20px;
        }
        .textBox {
          right: 30px;
          top: 50%;
        }
      }
    }
  }
  .theMiddle {
    width: 100%;
    overflow: hidden;
  }
  .productShow {
    height: auto;
    padding-top: 1.8rem !important;
    .tabsTitle {
      font-size: 2.8rem !important;
      margin-bottom: 1rem;
    }
    .relativeBox {
      display: none;
    }
    .relativeBox2 {
      height: auto;
      display: block;
      .productList {
        position: static;
        display: flex;
        flex-wrap: wrap;
        > div {
          padding-top: 1rem;
          width: 30%;
          margin: 0;
          margin-right: 4%;
          margin-top: 2rem;
          height: 18rem;
          img {
            margin-top: 1rem;
            width: 90%;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        // .explain {
        //   display: none !important;
        // }
      }
    }
  }
}
</style>




