<template>
  <div class="bottomBar">
    <div>
      <div class="title">公司信息</div>
      <div>
        <div>公司：YCHEN T&T S.T</div>
        <div>CIF：ESB-10814572</div>
        <div>地址：calle milanos numero 8, nave 7 28320 pinto, madrid</div>
      </div>
    </div>
    <div>
      <div class="title">联系我们</div>
      <div>
        <div>电话：+34 622 717 365</div>
        <div>
          微信：
          <el-tooltip placement="top">
            <div slot="content">
              <img class="code"
                src="@/assets/bottomCode.png"
                alt="">
            </div>
            <span><span class="bottomLine">ychencl</span> <span class="bottomLine">(查看二维码)</span> </span>
          </el-tooltip>

        </div>
      </div>
    </div>
    <div>
      <div class="title">使用协议</div>
      <div>
        <div class="bottomLine"
          @click="toPage('/AgreementPrivacy')">隐私协议</div>
        <div class="bottomLine"
          @click="toPage('/AgreementUser')">用户协议</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottomBar',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toPage(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
.bottomBar {
  background: #527de7;
  padding: 60px 0 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  color: white;
  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .bottomLine {
    text-decoration: underline;
    cursor: pointer;
  }
}
.code {
  width: 150px;
  height: 150px;
}

@media screen and (max-width: 768px) {
  .bottomBar {
    flex-wrap: wrap;
    text-align: center;
  }
}
</style>
