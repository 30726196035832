<template>
  <div class="layout"
    @click="changeNum++">
    <navBar></navBar>
    <router-view></router-view>
    <bottomBar></bottomBar>
    <!-- <sideBar :changeNum="changeNum"></sideBar> -->
  </div>
</template>

<script>
import navBar from '@/components/navBar'
import bottomBar from '@/components/bottomBar'
export default {
  name: '',
  props: {},
  data() {
    return {
      changeNum: 0
    }
  },
  components: {
    navBar,
    bottomBar
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.layout {
  padding-top: 63px;
}
@media screen and (max-width: 768px) {
  .layout {
    padding-top: 6rem;
  }
}
</style>
