<template>
  <div>
    <div class="banner">
      <div class="computer">
        <div class="title">精品全渠道批发业务</div>
        <div class="smallTitle">满足批发商业务所需的各种销售场景</div>
        <div class="btn">预约演示</div>
      </div>
      <img src="@/assets/newAssets/wholesale1.png"
        alt="">
      <div class="phone">
        <div class="title">精品全渠道批发业务</div>
        <div class="smallTitle">满足批发商业务所需的各种销售场景</div>
      </div>
    </div>
    <div class="page2">
      <div>
        <div class="title">
          满足批发业务所需的各种销售场景
          <div></div>
        </div>
        <div class="content">
          <div>
            <img src="@/assets/page2img1.png">
            <div class="textContent">
              <div>
                <div class="subtitle">批发市场</div>
                <div>
                  进销存系统<br>
                  轻松管理门店生意
                </div>
              </div>

            </div>
          </div>
          <div>
            <img src="@/assets/page2img2.png">
            <div class="textContent">
              <div>
                <div class="subtitle">在线销售</div>
                <div>
                  APP、网上商城<br>
                  客户不到店一样购买
                </div>
              </div>

            </div>
          </div>
          <div>
            <img src="@/assets/page2img3.png">
            <div class="textContent">
              <div>
                <div class="subtitle">上门推销</div>
                <div>
                  推销员管理系统<br>
                  随时、随地提升业绩
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page3">
      <div>
        <div class="title">
          支持批发业务涉及的各种经营模式
          <div></div>
        </div>
        <div class="content">
          <div>
            <img src="@/assets/newAssets/wholesale4.png">
            <div class="suntitle">
              分组价格
              <div></div>
            </div>
            <div>
              不同渠道、不同买家，<br>
              不同的价格策略
            </div>
          </div>
          <div>
            <img src="@/assets/newAssets/wholesale5.png">
            <div class="suntitle">
              阶梯价格
              <div></div>
            </div>
            <div>
              客户购买量不同，采用<br>
              不同的销售价格
            </div>
          </div>
          <div>
            <img src="@/assets/newAssets/wholesale6.png">
            <div class="suntitle">
              现货后款
              <div></div>
            </div>
            <div>
              支持赊账。根据账期结算<br>
              货款，同时自动记账
            </div>
          </div>
          <div>
            <img src="@/assets/newAssets/wholesale7.png">
            <div class="suntitle">
              预付货款
              <div></div>
            </div>
            <div>
              支持预存货款，按预存款<br>
              拿货，同时自动记账
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page4">
      <div>
        <div class="title">
          可靠的库存管理和财务管理
          <div></div>
        </div>
        <div class="content">
          <div>
            <img src="@/assets/page4Img1.png">
            <div class="textBox">
              <div class="bigText">物流</div>
              <div class="smallText">
                从进货、库存、销售到配货，<br>每件商品都可追
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/page4Img2.png">
            <div class="textBox">
              <div class="bigText">资金流</div>
              <div class="smallText">
                与客户和供应商之间，每笔账<br>目来往都一清二
              </div>
            </div>
          </div>
          <!-- <img src="@/assets/page4Img1.png">
          <div class="iconBox">
            <div>
              <img src="@/assets/page4Img2.png">
              <div class="subtitle">物流</div>
              <div>从进货、库存、销售到配货，每件商品都可追溯</div>
            </div>
            <div>
              <img src="@/assets/page4Img3.png">
              <div class="subtitle">资金流</div>
              <div>与客户和供应商之间，每笔账目来往都一清二楚</div>
            </div> 
        </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.phone {
  display: none;
}
.computer {
  display: block;
}
.banner {
  padding: 125px 80px 58px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .title,
  .smallTitle {
    font-size: 35px;
    font-weight: 500;
    color: #333333;
    line-height: 67px;
    letter-spacing: 2px;
  }
  .smallTitle {
    font-size: 22px;
    margin-bottom: 56px;
  }
  .btn {
    text-align: center;
    width: 166px;
    height: 56px;
    background: #275de1;
    border-radius: 32px;
    color: white;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    line-height: 56px;
  }
  img {
    width: 600px;
    margin-left: 80px;
  }
}
.page2 {
  width: 100%;
  padding-top: 42px;
  padding-bottom: 47px;
  position: relative;
  background: #f9faff;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 37px;
    font-weight: 500;
    color: #333333;
    line-height: 52px;
    letter-spacing: 2px;
    text-align: center;
    > div {
      width: 175px;
      height: 5px;
      background: #2e93fe;
      opacity: 0.6;
      margin: 0 auto;
      margin-top: 16px;
    }
  }
  .content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    > div {
      position: relative;
      width: 377px;
      height: 242px;
      box-shadow: 0 0 30px 1px #c9c9c9;
      border-radius: 15px;
      > img {
        width: 100%;
        height: 100%;
      }
      .textContent {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 30px;
        // justify-content: center;
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: 32px;
        .subtitle {
          font-size: 26px;
          font-weight: 600;
          color: #ffffff;
          line-height: 36px;
          margin-bottom: 34px;
        }
      }
    }
  }
}
.page3 {
  padding: 30px 0 42px 0;
  width: 100%;
  position: relative;
  background: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 37px;
    font-weight: 500;
    color: #333333;
    line-height: 52px;
    letter-spacing: 2px;
    text-align: center;
    > div {
      width: 175px;
      height: 5px;
      background: #2e93fe;
      opacity: 0.6;
      margin: 0 auto;
      margin-top: 16px;
    }
  }
  .content {
    margin-top: 38px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    > div {
      padding-top: 42px;
      width: 270px;
      height: 330px;
      background: white;
    }
    img {
      width: 98px;
      height: 98px;
      margin-bottom: 35px;
    }
    .suntitle {
      font-size: 21px;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
      margin-bottom: 18px;
      div {
        width: 47px;
        height: 3px;
        background: #dbdbdb;
        margin: 0 auto;
        margin-top: 14px;
      }
    }
    font-size: 17px;
    font-weight: 500;
    color: #1d1d1d;
    line-height: 23px;
  }
}
.page4 {
  width: 100%;
  position: relative;
  background: #f9faff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 48px 0;
  .title {
    font-size: 37px;
    font-weight: 500;
    color: #333333;
    line-height: 52px;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 26px;
    > div {
      width: 175px;
      height: 5px;
      background: #2e93fe;
      opacity: 0.6;
      margin: 0 auto;
      margin-top: 16px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    > div {
      position: relative;
      width: 544px;
      height: 318px;
      box-shadow: 0 0 30px 1px #c9c9c9;
      border-radius: 22px;
      > img {
        width: 100%;
      }
      .textBox {
        position: absolute;
        left: 44px;
        top: 80px;
        .bigText {
          font-weight: 600;
          font-size: 32px;
          color: #333333;
          line-height: 45px;
          // text-align: right;
          font-style: normal;
        }
        .smallText {
          font-weight: 400;
          font-size: 20px;
          color: #333333;
          line-height: 29px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .phone {
    display: block;
  }
  .computer {
    display: none;
  }
  .middleBox {
    width: 100%;
  }
  .page2,
  .page3,
  .page4 {
    .title {
      font-size: 2.2rem;
      line-height: normal;
    }
    .content {
      width: 100%;
    }
  }

  .banner {
    padding: 0;
    flex-wrap: wrap;
    padding: 1.2rem 0;
    > img {
      height: auto;
      width: 100%;
      margin-left: 0;
    }
    .phone {
      width: 100%;
      padding: 0 10px;
      text-align: center;
    }
    .title {
      font-size: 2.2rem;
      line-height: normal;
      margin-top: 10px;
    }
    .smallTitle {
      font-size: 1.6rem;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  .page2 {
    .content {
      flex-wrap: wrap;
      > div {
        width: 100% !important;
        margin-bottom: 10px;
        .textContent {
          width: auto;
          // margin-left: ;
          // margin: 0;
        }
      }
    }
  }
  .page3 {
    .content {
      flex-wrap: wrap;
      > div {
        width: 96%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
  }
  .page4 {
    padding-bottom: 0;
    .content {
      flex-wrap: wrap;
      > div {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        > .textBox {
          left: 20px;
          top: 25px;
        }
      }
      // .iconBox {
      //   > div {
      //     padding: 0 20px;
      //     font-size: 1.2rem;
      //     img {
      //       width: 5rem;
      //       height: 5rem;
      //     }
      //     .subtitle {
      //       font-size: 1.8rem;
      //       line-height: normal;
      //       margin: 0;
      //       margin: 8px 0;
      //     }
      //   }
      // }
    }
  }
}
</style>
