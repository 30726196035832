<template>
  <div>
    <div class="page9">
      <div>
        <div class="line">
          <div class="item">
            <div class="logo">
              <img src="@/assets/page9img1.png">
            </div>
            <div class="appname">批发零售商家端</div>
            <div class="adrBtn">
              <a href="https://download.24ktpv.com/24kpda.apk">安卓下载</a>
            </div>
            <div class="iosBtn">苹果下载</div>
            <div class="code">
              <!-- <el-tooltip class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  <img src="@/assets/page9img6.png"
                    class="codeImg">
                </div>
                <span>二维码下载</span>
              </el-tooltip> -->
              <img src="@/assets/page9img6.png"
                class="codeImg">
            </div>
          </div>
          <div class="item">
            <div class="logo">
              <img src="@/assets/page9img2.png">
            </div>
            <div class="appname">批发商点货APP</div>
            <div class="adrBtn">
              <a href="https://download.24ktpv.com/24kbuy.apk">安卓下载</a>
            </div>
            <div class="iosBtn">苹果下载</div>
            <div class="code">
              <!-- <el-tooltip class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  <img src="@/assets/page9img7.png"
                    class="codeImg">
                </div>
                <span>二维码下载</span>
              </el-tooltip> -->
              <img src="@/assets/page9img7.png"
                class="codeImg">
            </div>
          </div>
          <div class="item">
            <div class="logo">
              <img src="@/assets/page9img3.png">
            </div>
            <div class="appname">推销员APP</div>
            <div class="adrBtn">
              <a href="https://download.24ktpv.com/salesman.apk">
                安卓下载
              </a>
            </div>
            <!-- <div class="iosBtn">苹果下载</div> -->
            <div class="code">
              <!-- <el-tooltip class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  <img src="@/assets/page9img8.png"
                    class="codeImg">
                </div>
                <span>二维码下载</span>
              </el-tooltip> -->
              <img src="@/assets/page9img8.png"
                class="codeImg">
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="logo">
              <img src="@/assets/page9img4.png">
            </div>
            <div class="appname">批发零售收银APP</div>
            <div class="adrBtn">
              <a href="https://download.24ktpv.com/24kpos.apk">
                安卓下载
              </a>
            </div>
            <div class="code">
              <!-- <el-tooltip class="item"
                effect="dark"
                placement="top">
                <div slot="content">
                  <img src="@/assets/page9img9.png"
                    class="codeImg">
                </div>
                <span>二维码下载</span>
              </el-tooltip> -->
              <img src="@/assets/page9img9.png"
                class="codeImg">
            </div>
          </div>
          <div class="item">
            <div class="logo">
              <img src="@/assets/page9img5.png">
            </div>
            <div class="appname">餐饮店收银APP</div>
            <div class="adrBtn">安卓下载</div>
            <div class="code">二维码下载</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.page9 {
  width: 100%;
  // height: 100vh;
  background: #d7e4ef;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
.line {
  display: flex;
  align-items: center;
  justify-content: center;
  > .item {
    width: 243px;
    height: 390px;
    background: linear-gradient(219deg, #ffffff 0%, #f2feff 100%);
    box-shadow: 0px 0 43px 0px rgba(9, 19, 50, 0.1);
    border-radius: 11px;
    margin-right: 72px;
    margin-bottom: 24px;
    position: relative;
    .logo {
      width: 80px;
      height: 80px;
      margin: 0 auto;
      margin-top: 16px;
      margin-bottom: 4px;
      img {
        width: 100%;
      }
    }
    .appname {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      text-align: center;
      margin-bottom: 16px;
    }
    .adrBtn {
      width: 80%;
      height: 40px;
      background: #47a0ff;
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 6px;
      font-size: 13px;
      line-height: 40px;
      text-align: center;
      font-weight: 600;
      color: #ffffff;
    }
    .iosBtn {
      width: 80%;
      height: 40px;
      background: #4bc4e5;
      border-radius: 5px;
      margin: 0 auto;
      font-size: 13px;
      line-height: 40px;
      text-align: center;
      font-weight: 600;
      color: #ffffff;
    }
    .code {
      position: absolute;
      bottom: 6px;
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
      color: #333333;
      cursor: pointer;
    }
    a {
      font-size: 13px;
      line-height: 40px;
      text-align: center;
      font-weight: 600;
      color: #ffffff;
      text-decoration: none;
    }
  }
  &:last-child {
    .item {
      margin-bottom: 0;
      .appname {
        margin-bottom: 40px;
      }
    }
  }
}
.codeImg {
  width: 150px;
  height: 150px;
}
@media screen and (max-width: 768px) {
  .page9 {
    position: static;
    height: auto;
    padding-top: 30px;
    .line {
      flex-wrap: wrap;
      > div {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 20px !important;
      }
    }
  }
}
</style>
