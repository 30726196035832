<template>
  <div class="Agreement">
    <div class="content">

      <h2>política de privacidad</h2>
      <div class="rightRed">Actualizado el 23 de mayo de 2022</div>

       <div class="subTitle">Nota especial</div>

       
      <div>Suzhou digital INTELLIGENT TECHNOLOGY co., Ltd. (en adelante, "nosotros") presta gran atención a la protección de la información personal de los usuarios (en adelante, "usted"), somos conscientes de la importancia de la información personal para usted, tomaremos las medidas de protección de seguridad correspondientes para proteger su información personal de acuerdo con los requisitos de las leyes y reglamentos y las normas de Seguridad maduras de la industria. En vista de esto, formulamos esta política de privacidad (en adelante, "esta política") y le recordamos que esta política se aplica a productos y servicios de 24ktpv (en adelante, "este servicio")</div>
      <div>Cabe señalar en particular que esta política no se aplica a los servicios prestados por otros terceros a usted. Por ejemplo, para los servicios de terceros conectados en productos 24ktpv, la información personal que usted proporciona a los proveedores de servicios de terceros no se aplica a esta política.</div>
      <div>Antes de utilizar este servicio, asegúrese de leer y comprender a fondo esta política, después de confirmar que comprende y acepta plenamente el uso de los productos o servicios pertinentes, una vez que comience a utilizar nuestros productos o servicios, es decir, que ha comprendido y aceptado plenamente esta política.</div>
       

       <div class="subTitle">Política de privacidad</div>
       
      <div class="none">1、Cómo recopilamos y utilizamos su información personal</div>
      <div class="none">2. cómo usamos cookies y tecnologías similares</div>
      <div class="none">3、Cómo compartimos, transferimos y divulgamos públicamente su información personal</div>
      <div class="none">4、Cómo protegemos su información personal</div>
      <div class="none">5、¿Cómo gestionan su información personal?</div>
      <div class="none">6. Cómo tratamos la información personal de los menores</div>
      <div class="none">7. cómo se actualiza esta política de privacidad</div>
      <div class="none">8. Cómo ponerse en contacto con nosotros</div>
       
      <div class="subTitle"> i. cómo recogemos y utilizamos su información</div>

       <div>Podemos recopilar y utilizar su información personal para los siguientes fines descritos en esta política.</div>

       <div class="subTitle">(1) le ofrece un servicio de 24ktpv</div>
       
      <div class="subTitle">1. la información que nos facilite</div>
      <div>
        Para facilitar la prestación de productos y servicios a usted, tenemos derecho a recopilar la información de contacto (nombre, sexo, número de teléfono, etc.), la información de licencia comercial, la información del Representante legal, el correo electrónico, la información de la cuenta bancaria u otros instrumentos de pago que nos proporcione desde múltiples ángulos, como la verificación de identidad, la finalización de la transacción, El servicio post - venta y al cliente, la seguridad, etc. Cualquier texto, foto y otras formas de información que presente durante el uso del Servicio de productos 24ktpv también puede contener o asociar su información personal, y protegeremos estrictamente la información personal involucrada en la información anterior.
      </div>

       <div class="subTitle">2. la información que recopilamos en el curso de su uso de los Servicios</div>

      <div>Con el fin de ofrecerle páginas y resultados de búsqueda más personalizados, comprender la idoneidad de los productos e identificar anomalías en las cuentas, podemos recopilar y correlacionar información sobre los servicios que utiliza y cómo los utiliza, incluyendo.</div>
      <div>Información del dispositivo: recibiremos y registraremos la información relacionada con el dispositivo que utiliza (como el modelo del dispositivo, la versión del sistema operativo y otras características de software y hardware e información del entorno del dispositivo) de acuerdo con los permisos específicos que otorgue en la instalación y uso del software, Información relacionada con la ubicación del dispositivo (como dirección ip, ubicación GPS e información de sensores como puntos de acceso Wi - fi, Bluetooth y estaciones base capaces de proporcionar información relevante).</div>
      <div>Información de registro: cuando utiliza los productos o servicios proporcionados por nuestro sitio web o cliente, recopilamos automáticamente los detalles de su uso de nuestros servicios y los guardamos como registros de red relevantes. Por ejemplo, su dirección ip, el tipo de navegador, el operador de telecomunicaciones, el idioma utilizado, la fecha y hora de acceso y el registro de la página web a la que visita.</div>
      <div>Tenga en cuenta que la información del dispositivo, la información de registro, etc. por sí sola no puede identificar a una persona física específica. Si utilizamos dicha información no personal en combinación con otra información con el fin de identificar a una persona física concreta o en combinación con información personal, dicha información no personal se tratará como información personal mientras dure dicho uso combinado y se anonimizará y desidentificará, a menos que hayamos obtenido su autorización o que la ley o la normativa nos obliguen a hacerlo.</div>
      <div>Cuando se pone en contacto con nosotros, podemos conservar registros de su correspondencia/llamadas y su contenido o información como los datos de contacto que ha dejado, con el fin de ponernos en contacto con usted o ayudarle a resolver sus problemas, o para registrar las soluciones y resultados de problemas relacionados.</div>
       
      <div class="subTitle">(ii) proporcionarle seguridad</div>
       
      <div>Con el fin de mejorar la seguridad de su uso de los servicios prestados por nosotros y nuestras empresas afiliadas y socios, proteger su seguridad personal y patrimonial u otros usuarios o el público, prevenir con mayor precisión el fraude en los sitios web de phishing y los virus troyanos, e identificar con mayor precisión las violaciones de las leyes y reglamentos o los Acuerdos de servicio al usuario de productos 24ktpv, podemos usar o integrar su información personal, información de transacciones, Información sobre el equipo, registros de red relevantes e información de nuestras empresas afiliadas y socios autorizadas por usted o compartidas de acuerdo con la ley para juzgar exhaustivamente los riesgos de su cuenta y transacción, realizar autenticación, detectar y prevenir incidentes de seguridad, y tomar las medidas necesarias de registro, auditoría, análisis y eliminación de acuerdo con la ley.</div>
       
      <div class="subTitle">(iii) Otros usos</div>
       
      <div>Le pediremos su consentimiento antes de utilizar la información para fines distintos de los descritos en esta política o de utilizar para otros fines la información recopilada para un fin específico.</div>
       
      <div class="subTitle">(iv) Excepciones al consentimiento autorizado</div>
       
      <div>Le informamos de que su consentimiento autorizado no es necesario para la recogida y uso de información personal en los siguientes casos.</div>
      <div>1. Las relacionadas con la seguridad nacional y la seguridad de la defensa; y</div>
      <div>2. En relación con la seguridad pública, la salud pública y el interés público significativo; y</div>
      <div>3. En relación con la investigación, el enjuiciamiento, la sentencia y la ejecución de las penas por delitos.</div>
      <div>4. Con el fin de salvaguardar la vida, la propiedad y otros derechos e intereses legítimos importantes del sujeto de la información personal u otras personas, pero es difícil obtener su consentimiento.</div>
      <div>5. La información personal recogida es divulgada al público por el propio sujeto de la información personal.</div>
      <div>6. Su información personal recopilada de información legítima divulgada públicamente, como informes de noticias legítimos, divulgación de información gubernamental y otros canales.</div>
      <div>7. Necesario para firmar el contrato a petición suya; y</div>
      <div>8. Necesarios para mantener el funcionamiento seguro y estable de los productos y/o servicios prestados, por ejemplo, para detectar y hacer frente a fallos de los productos y/o servicios.</div>
      <div>9. Necesario para el periodismo legítimo; y</div>
      <div>10. Si una organización de investigación académica lleva a cabo investigaciones estadísticas o académicas necesarias para el interés público, y si proporciona los resultados de las investigaciones o descripciones académicas al público, desidentifica la información personal contenida en los resultados.</div>
      <div>11. Otros casos previstos por las leyes y reglamentos.</div>
       
      <div class="subTitle">2. cómo usamos cookies y tecnologías similares</div>
       
      <div>Cuando utiliza este software, podemos usar cookies y tecnologías similares para recopilar su información para mejorar el servicio del producto y la experiencia del usuario, detectar y prevenir riesgos de Seguridad a tiempo. No usaremos las cookies para ningún propósito más allá de los fines mencionados en esta política, y usted puede mantenerlas o eliminarlas de acuerdo con sus propias preferencias. Puede eliminar todas las cookies reservadas en el software, y su información relevante ha sido eliminada cuando las borre manualmente.</div>
       
      <div class="subTitle">Cómo compartimos, transferimos y divulgamos públicamente su información personal</div>
       
      <div class="subTitle">(i) Compartir</div>
       
      <div>No compartimos su información personal con empresas, organizaciones o particulares ajenos a nosotros, salvo en las siguientes circunstancias.</div>
      <div>1. Compartir con su consentimiento explícito o autorización: Compartiremos su información personal con otras partes después de obtener su consentimiento explícito.</div>
      <div>2. Compartir bajo circunstancias legales: Podemos compartir su información personal de acuerdo con las disposiciones de las leyes y reglamentos, la necesidad de resolución de litigios, o a petición de las autoridades administrativas o judiciales de conformidad con la ley.</div>
      <div>3. Compartir con empresas afiliadas: Compartir con nuestras empresas afiliadas: Su información personal puede ser compartida con nuestras empresas afiliadas. Sólo compartiremos la información personal que sea necesaria y esté sujeta a los fines establecidos en esta Política de Privacidad. Las empresas afiliadas solicitarán su autorización para modificar los fines para los que se procesa la información personal.</div>
      <div>4. Podremos confiar a socios autorizados la prestación de determinados servicios o el desempeño de funciones en nuestro nombre, sólo compartiremos su información para fines lícitos, legítimos, necesarios, específicos y claros, los socios autorizados sólo tendrán acceso a la información necesaria para el desempeño de sus funciones, y no podrán utilizar esta información para ningún otro fin.</div>
      <div>Actualmente, entre nuestros socios autorizados se encuentran los siguientes tipos.</div>
      <div>①Socios autorizados para servicios de publicidad y análisis. Sin su autorización, no compartiremos su información personal con socios que presten servicios de publicidad y análisis. Sin embargo, podemos utilizar su información para formar perfiles de usuario y compartirlos con socios que prestan servicios de publicidad y análisis para ayudarles a mejorar el alcance efectivo de la publicidad y la información de servicio sin identificarle personalmente.</div>
      <div>Vendedores, proveedores de servicios y otros socios. Enviamos información a vendedores, proveedores de servicios y otros socios que apoyan nuestro negocio proporcionando servicios de infraestructura técnica, analizando cómo se utilizan nuestros servicios, midiendo la eficacia de los anuncios y servicios, proporcionando servicio al cliente, mejorando y promocionando nuestros productos y servicios, o realizando investigaciones académicas y encuestas. Las empresas, organizaciones y particulares con los que compartimos información personal están sujetos a estrictos acuerdos de protección de datos que les obligan a tratar la información personal de acuerdo con nuestras instrucciones, la presente Política de Privacidad y cualquier otra medida de confidencialidad y seguridad pertinente.</div>
       
      <div class="subTitle">(ii) Transferencias</div>
       
      <div>No transferimos su información personal a ninguna empresa, organización o particular, con las siguientes excepciones.</div>
      <div>1. Transferencia con consentimiento explícito: Transferiremos su información personal a terceros tras obtener su consentimiento explícito.</div>
      <div>2. En caso de fusión, adquisición o liquidación, o de cualquier otra situación que implique una fusión, adquisición o liquidación que conlleve la transferencia de información personal, exigiremos a la nueva empresa u organización que posea su información personal que siga estando vinculada por esta política, o exigiremos a dicha empresa, organización o persona que solicite de nuevo su consentimiento autorizado.</div>
       
      <div class="subTitle">(iii) Divulgación pública</div>
       
      <div>Sólo divulgaremos públicamente sus datos personales cuando.</div>
      <div>1、Con su consentimiento explícito o sobre la base de su elección activa, podemos divulgar públicamente su información personal.</div>
      <div>2. Si determinamos que usted ha infringido las leyes y reglamentos o violado gravemente las normas de nuestros acuerdos pertinentes, o con el fin de proteger nuestra seguridad y la de los usuarios de nuestras filiales o la seguridad personal y de la propiedad del público de ser infringida, podemos, de conformidad con las leyes y reglamentos o las normas de nuestros acuerdos pertinentes, obtener su consentimiento para revelar su información personal, incluyendo la violación pertinente de la ley y las medidas que hemos tomado contra usted.</div>
       
      <div class="subTite">(iv) Excepciones a la obtención del consentimiento previo autorizado para compartir, transferir y divulgar públicamente información personal</div>
       
      <div>El intercambio, la transferencia y la divulgación pública de sus datos personales no requieren su consentimiento previo autorizado en los siguientes casos.</div>
      <div>1. Las relacionadas con la seguridad nacional y la seguridad de la defensa; y</div>
      <div>2. En relación con la seguridad pública, la salud pública y el interés público significativo; y</div>
      <div>3. En relación con la investigación, el enjuiciamiento, la sentencia y la ejecución de las penas por delitos.</div>
      <div>4. Con el fin de salvaguardar la vida, la propiedad y otros derechos e intereses legales importantes suyos o de otras personas, pero es difícil obtener mi consentimiento; y</div>
      <div>5. Información personal que revele al público según su propio criterio.</div>
      <div>6. Los datos personales se recopilan a partir de información lícita divulgada públicamente, como informes de noticias lícitos, divulgación de información gubernamental y otros canales. De acuerdo con la ley, compartir o transferir información personal que ha sido desidentificada y garantizar que el receptor de los datos no puede recuperar y volver a identificar al sujeto de la información personal no es un acto de compartir, transferir o divulgar públicamente la información personal al mundo exterior, y la conservación y el tratamiento de dichos datos se llevarán a cabo sin ninguna notificación por separado a usted y su consentimiento</div>
       
      <div class="subTitle">iv. cómo protegemos la seguridad de su información personal</div>
       
      <div>(a) Disponemos de un avanzado sistema de gestión de la seguridad de los datos, desde la construcción organizativa, el diseño del sistema, la gestión del personal, la tecnología del producto y otros aspectos de mejora multidimensional de la seguridad de todo el sistema.</div>
       
      <div>(b) Tomaremos las medidas razonablemente viables para evitar recopilar información personal irrelevante. Solo conservaremos su información personal durante el tiempo necesario para cumplir los fines establecidos en esta política, a menos que necesitemos ampliar el periodo de conservación o la ley nos lo permita.</div>
       
      <div>(c) Internet no es un entorno absolutamente seguro, le recomendamos encarecidamente que utilice contraseñas complejas de forma segura para ayudarnos a garantizar la seguridad de su cuenta.</div>
       
      <div>(d) En el desafortunado caso de un incidente de seguridad de la información personal, le informaremos de acuerdo con los requisitos de las leyes y reglamentos: la situación básica del incidente de seguridad y el posible impacto, las medidas que hemos tomado o tomaremos para hacer frente a la situación, las sugerencias que usted puede tomar precauciones independientes para prevenir y reducir el riesgo, y las medidas correctivas para usted, y así sucesivamente. Le informaremos del incidente por correo electrónico, carta, teléfono, notificación push, etc. Si resulta difícil informar al sujeto de la información personal uno por uno, adoptaremos una forma razonable y eficaz de hacer un anuncio público.</div>
       
      <div>También informaremos sobre la gestión de los incidentes relacionados con la seguridad de la información personal, tal y como exigen las autoridades reguladoras.</div>
       
      <div class="subTitle">V. ¿Cómo gestionan su información personal?</div>
       
      <div class="none">(i) acceso a su información personal</div>
      <div>Usted tiene derecho a acceder a su información personal, sin perjuicio de las excepciones previstas por la ley o la normativa. Si desea acceder a la información del perfil de su cuenta o modificarla, cambiar su contraseña, añadir información de seguridad o cerrar su cuenta, puede hacerlo a través de "Administración del sistema"</div>
       
      <div class="none">(ii) Tiene derecho a corregir o completar su información personal</div>
       
      <div class="none">(c) Tiene derecho a borrar su información personal.</div>
       
      <div class="none">(iv) Tiene derecho a modificar el alcance de su consentimiento autorizado.</div>
       
      <div>Si cree que hemos recopilado o utilizado sus datos personales infringiendo la ley, puede ponerse en contacto con nosotros a través de nuestro servicio de atención al cliente.</div>
       
      <div class="subTitle">VI. Cómo tratamos los datos personales de los menores</div>
       
      <div>Los menores no pueden crear sus propias cuentas de usuario sin el consentimiento de sus padres o tutores. Si es usted menor de edad, le recomendamos que pida a sus padres o tutores que lean atentamente esta Política de Privacidad y que utilice nuestros servicios o nos facilite información con el consentimiento de sus padres o tutores. En el caso de que recopilemos información personal de menores a través del uso de nuestros productos o servicios con el consentimiento de sus padres o tutores, sólo utilizaremos, compartiremos, transferiremos o revelaremos esta información según lo permita la ley o la normativa, con el consentimiento expreso de sus padres o tutores, o según sea necesario para proteger a los menores.</div>
       
      <div class="subTitle">VII. Cómo se ha actualizado esta política de privacidad</div>
       
      <div>Los servicios y productos que ofrecemos se actualizarán y ajustarán de vez en cuando, y la política de privacidad también puede cambiar. para cambios importantes, proporcionaremos avisos más significativos (incluidos los avisos e incluso le proporcionaremos consejos de ventanas emergentes a través de nuestra publicidad). por favor, revise la última política de privacidad a tiempo. En las circunstancias anteriores, si continúa utilizando nuestros servicios, expresa su consentimiento para aceptar la política revisada y estar sujeto a ella. El derecho de interpretación final de esta política de privacidad pertenece a la empresa.</div>
       
      <div class="subTitlr">VIII. Cómo ponerse en contacto con nosotros</div>
       
      <div>Puede ponerse en contacto con nosotros a través de la información de contacto publicada en nuestro sitio web oficial con una descripción completa, y responderemos a su solicitud e intentaremos por todos los medios resolverla en un plazo de 30 días tras verificar su identidad. Si no está satisfecho con nuestra respuesta, especialmente si cree que nuestro comportamiento en el tratamiento de la información personal ha dañado sus derechos e intereses legítimos, también puede buscar una solución presentando una demanda ante el tribunal de la jurisdicción competente en el lugar donde Suzhou Numerical Intelligence Technology Co.</div>

    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.Agreement {
  background: #b7c0c3;
  overflow: hidden;
}

.content {
  width: 900px;
  padding: 20px;
  margin: 30px auto;
  background: white;
  h2 {
    text-align: center;
  }
  .rightRed {
    text-align: right;
    color: rgb(255, 63, 63);
  }
  .subTitle {
    font-weight: bold;
  }
  div:not([class]) {
    text-indent: 2em;
  }
}
@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    margin: 0;
  }
}
</style>
